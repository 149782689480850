<template>
  <div id="app">
    <v-app id="inspire">
      <v-navigation-drawer v-model="drawer" color="#744D9c" dark app>
        <v-list height="150" class="mt-4">
          <a-row type="flex" justify="center" align="middle">
            <a-col :span="24" class="centerdiv">
              <v-img
                src="@/assets/aranyik_logo_white.png"
                max-width="120px"
                max-height="120px"
              />
            </a-col>
            <a-col :span="24" class="centerdiv">
              <span class="headline">{{ username }} </span>
            </a-col>
          </a-row>
        </v-list>
        <v-divider></v-divider>
        <a-menu mode="vertical" style="background: #744D9c;" :theme="theme">
          <a-menu-item
            v-for="item in Navigator"
            :key="item.key"
            :icon="item.icon"
            @click="ChangePage(item)"
            style="font-size: 16px; font-weight: 800"
          >
            <a-icon style="color: white;" :type="item.icon"></a-icon>
            <span class="pl-5" style="color: white;">{{ item.text }}</span>
          </a-menu-item>
        </a-menu>
      </v-navigation-drawer>
      <v-app-bar app color="#744D9c" dark>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>{{ Header }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text @click="Logout">ออกจากระบบ</v-btn>
      </v-app-bar>
      <v-main class="div_overflow">
        <v-container grid-list-xs>
          <router-view></router-view>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
<script>
// import {  "@/services";
export default {
  data() {
    return {
      drawer: true,
      Header: "",
      picture: "",
      username: "",
      theme: "dark",
      Navigator: [
        {
          key: "1",
          text: "จัดการผู้สมัครสมาชิก",
          path: "/",
          name: "table",
          icon: "user",
        },
        {
          key: "2",
          text: "จัดการข่าว",
          path: "/showallnews",
          name: "showallnews",
          icon: "notification",
        },
        {
          key: "3",
          text: "รายการขอแบบประเมิน",
          path: "/requestDocument",
          name: "requestDocument",
          icon: "paper-clip",
        },
        {
          key: "5",
          text: "ดูรายการ ภ.ด.ส.1",
          path: "/pds1",
          name: "pds1",
          icon: "file",
        },
        {
          key: "4",
          text: "ดูรายการ ภ.ด.ส.3",
          path: "/pds3",
          name: "pds3",
          icon: "file",
        },
        {
          key: "6",
          text: "ดูรายการ ภ.ด.ส.7",
          path: "/pds7",
          name: "pds7",
          icon: "file",
        },
        {
          key: "7",
          text: "อัพโหลดไฟล์",
          path: "/uploadcsv",
          name: "uploadcsv",
          icon: "upload",
        },
      ],
    };
  },
  methods: {
    ChangePage(val) {
      // console.log('เข้า ChangePage', val)ห
      // console.log(val, this.$router.currentRoute)
      if (val.name !== this.$router.currentRoute.name) {
        this.$router.push({ path: val.path }).catch(() => {});
      }
    },
    CheckHeader(val) {
      this.Header = val;
    },
    created() {
      // console.log('เข้า create home page')
      // this.$EventBus.$on("StatusHeader", (val) => {
      //   this.CheckHeader(val);
      //}
      //);

      var checkuser = JSON.parse(localStorage.getItem("user")); //
      if (checkuser === "") {
        //   console.log('เข้านะ');
        this.$router.push("/login");
      }
      // this.CheckHeader();
    },
    async Logout() {
      localStorage.removeItem("user");
      await this.$swal.fire({
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        icon: "success",
        text: "ออกจากระบบเสร็จสิ้น",
      });
      this.$router.push("/login");
    },
  },
};
</script>
<style scoped>
.centerdiv {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.div_overflow {
  overflow: auto;
  width: 100%;
  height: 89vh;
  /* background-color: #f5cdfc; */
}
.v-application ul,
.v-application ol {
  padding: 0px 0px !important;
  /* background-color: #f5cdfc; */
}
</style>
