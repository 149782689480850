import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'ant-design-vue/dist/antd.css'
import Antd from 'ant-design-vue'

Vue.config.productionTip = false
Vue.use(VueSweetalert2)
Vue.use(Antd)

new Vue({
  router,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
